import {mapActions, mapGetters, mapMutations} from "vuex";
import {dynamicHead} from '@/mixins/dynamic-head.js'
export default {
  name: "page-not-found",
  mixins: [dynamicHead],
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  'head': function () {
    return {
      title: () => {
        return {inner: this.params.title && this.params.title.length ? this.params.title : 'David Roytman',
          separator: ' ',
          complement: ' '}
      },
      meta:  () => {
        return [
          {n: 'prerender-status-code', content: '404', id: 'prerender-status'},
        ]
      },
    }
  },
  created() {
      this.setMeta()
  },
  mounted() {
  },
  computed:{
    ...mapGetters({
    })
  },

  methods:{
    ...mapActions({
    }),
    ...mapMutations({
    }),
  }
}